import { EnvironmentContract } from '@environments/environment-contract';

export const environment: EnvironmentContract = {
    client: 'nat',
    production: true,
    environment: 'prod',
    enableDebugTools: false,
    apiUrl: 'https://tool.nachhaltigaustria.at/api',   // For testing the pdf generator use 'http://host.docker.internal:8035/api'
    allowedJwtDomains: ['tool.nachhaltigaustria.at',/^localhost/],
    markMeasureAsExcludedEnabled: false,
    sentryDsn: 'https://2a83078b9a814df08b7438fb44f71d11@sentry.webmozarts.com/9',
    sentryEnvironment: 'prod',
    sentryShowErrorDialog: false,
    sentryTracesSampleRate: 0.2,
    sentryRelease: 'nat@20.10',
    releaseVersion: '20.10',
    releaseTimestamp: 1732793351,
    minExpressionValueShareDisplay: 0.01,
    enableRouterTracing: false,
    logo: 'logo.svg',
    loginImg: 'nha-label.svg',
};
